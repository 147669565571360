import { useQuery } from '@tanstack/react-query';
import courseKeys from '../../query-keys/course-key-factory';
import sessionKeys from '../../query-keys/session-key-factory';
import {
  getCourse,
  getCoursesSessionsForCourse,
  getMembersForCourse,
  getCourseStages
} from '../../services/api/course.service';

// hook for managing a single course
const useCourse = ({
  courseId,
  displayLocale
}: {
  courseId: number | null | undefined;
  displayLocale: string | undefined;
}) => {
  if (!courseId) {
    throw Error('invalid course id');
  }

  const courseQuery = useQuery({
    queryKey: courseKeys.course(courseId, displayLocale),
    queryFn: () => getCourse(courseId, displayLocale),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    enabled: !!courseId
  });

  const courseSessionsForCourseQuery = useQuery({
    queryKey: sessionKeys.course(courseId, displayLocale),
    queryFn: () => getCoursesSessionsForCourse(courseId, displayLocale),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    enabled: !!courseId
  });

  const learnersForCourseQuery = useQuery({
    queryKey: courseKeys.learnersOnCourseList(courseId, displayLocale),
    queryFn: () => getMembersForCourse(courseId, displayLocale),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    enabled: !!courseId,
    retry: (failureCount: number, error: any) => {
      if (error?.response?.data === 'No Members Found Found') return false;
      if (failureCount >= 3) return false;
      return false;
    }
  });

  const courseStagesQuery = useQuery({
    queryKey: courseKeys.courseStages(courseId, displayLocale),
    queryFn: () => getCourseStages(courseId, displayLocale),
    refetchOnMount: true
  });

  return {
    courseQuery,
    courseSessionsForCourseQuery,
    learnersForCourseQuery,
    courseStagesQuery
  };
};

export default useCourse;

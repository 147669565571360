/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */

import axios from 'axios';

import pascalcaseKeys from 'pascalcase-keys';
import camelCaseResponse from '../camel-case-interceptor';
import { components } from '../../types/openapi/AuthService';
import { bearerToken } from '../bearer-token';
import { StorageId } from '../../const/storage-id';

const BASE_URL = `${process.env.REACT_APP_AUTH_API_URL}/api`;

export const authApi = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json' }
});

authApi.interceptors.request.use(
  (config) => {
    // add auth to all requests apart from login
    if (config.url !== '/auth/login' && config.url !== 'auth/token/refresh') {
      const token: string = bearerToken();
      if (token && config && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

authApi.interceptors.response.use(
  (response) => camelCaseResponse(response),
  (error) => Promise.reject(error)
);

export const login = async (loginDetails: components['schemas']['Login']) => {
  const response = await authApi
    .post<components['schemas']['JwtTokenResponse']>('auth/login', loginDetails)
    .then((res) => {
      localStorage.setItem('sso', JSON.stringify(false));

      if (res.data.token) {
        localStorage.setItem(StorageId.TOKEN, JSON.stringify(res.data.token));
      }
      return res;
    });
  return response.data;
};

export const captchaValidation = async (captchaValue: string, data: any) => {
  const response = await authApi
    .post<boolean>('auth/captcha', { captchaValue, ...data })
    .then((res) => {
      return res;
    });
  return response.data;
};

export const refreshAccessToken = async () => {
  const token = localStorage.getItem(StorageId.TOKEN);
  const refreshToken = localStorage.getItem(StorageId.REFRESH_TOKEN);
  if (token && refreshToken) {
    const body = {
      token: JSON.parse(token),
      refreshToken: refreshToken.startsWith('"')
        ? JSON.parse(refreshToken)
        : refreshToken
    };

    const response = await authApi
      .post<components['schemas']['JwtTokenResponse']>(
        'auth/token/refresh',
        body
      )
      .then((res) => {
        if (res.data) {
          if (res.data.token) {
            localStorage.setItem(
              StorageId.TOKEN,
              JSON.stringify(res.data.token)
            );
          }
          if (res.data.refreshToken)
            localStorage.setItem(
              'refreshToken',
              JSON.stringify(res.data.refreshToken)
            );
        }
        return res;
      });
    return response.data;
  }
  throw Error('token or refreshtoken missing');
};

export const requestResetEmail = async (
  userName: components['schemas']['PasswordBody']['userName']
) => {
  const userObj = { userName };
  const body = pascalcaseKeys(userObj, { deep: true });
  const response = await authApi.post<
    components['schemas']['PasswordBody']['userName']
  >('/auth/sendpasswordresetemail', body);

  return response.data;
};

export const resetPassword = async (
  PasswordBody: components['schemas']['PasswordBody']
) => {
  const body = PasswordBody;
  const response = await authApi.post<components['schemas']['PasswordBody']>(
    '/auth/resetpassword',
    body
  );

  return response.data;
};

export const setPassword = async (
  PasswordBody: components['schemas']['PasswordBody']
) => {
  const body = PasswordBody;
  const response = await authApi.post<components['schemas']['PasswordBody']>(
    '/auth/setpassword',
    body
  );

  return response.data;
};

export const loginSSO = async (guid: string) => {
  const response = await authApi
    .post<components['schemas']['SsoTokenResponse']>(
      `auth/sso/usersessions/${guid}`
    )
    .then((res) => {
      localStorage.setItem('sso', JSON.stringify(true));

      // If sso_frameworkId is already populated, clear
      if (localStorage.getItem('sso_frameworkId')) {
        localStorage.removeItem('sso_frameworkId');
      }

      if (res.data.token) {
        localStorage.setItem(StorageId.TOKEN, JSON.stringify(res.data.token));
      }

      if (res.data.frameworkId) {
        localStorage.setItem(
          'sso_frameworkId',
          JSON.stringify(res.data.frameworkId)
        );
      }

      return res;
    });
  return response.data;
};

export const validateResetPasswordToken = async (token: string) => {
  const params = { token };
  const response = await authApi.get<string>(`/auth/resetpassword/validate`, {
    params
  });

  return response.data;
};

export const impersonateUser = async (userId: number) => {
  if (!userId) {
    throw Error('no id provided');
  }
  const response = await authApi.get<components['schemas']['JwtTokenResponse']>(
    `auth/user/${userId}/impersonate`
  );

  return response.data;
};

import { LanguageStrings } from './base-strings';

export const cy: LanguageStrings = {
  'routes.home': '/',
  'routes.admin': 'gweinyddwr',
  'routes.login': 'mewngofnodi',
  'routes.contact': 'cysylltwch-a-ni',
  'routes.about': 'amdanom-ni',
  'routes.loginSSORedirect': 'mewngofnodi-ailgyfeirio',
  'routes.roleChange': 'newid-role',
  'routes.role.select': 'dewis-role',
  'routes.unauthorised': 'anawdurdodedig',
  'routes.login.forgotUsername': 'wedi-anghofio-enw-defnyddiwr',
  'routes.login.forgotPassword': 'anghofio-cyfrinair',
  'routes.login.request.forgotPassword': 'cais-newydd-cyfrinair',
  'routes.frameworks': 'fframweithiau',
  'routes.framework': 'fframwaith',
  'routes.createFramework': 'creu-fframwaith',
  'routes.manageFrameworkTranslation': 'rheoli-fframwaith-cyfieithu',
  'routes.manageTranslation': 'rheoli-cyfieithu',
  'routes.version': 'fersiwn',
  'routes.editVersion': 'golygu',
  'routes.stages': 'cyfnodau',
  'routes.addStage': 'ychwanegu-llwyfan',
  'routes.editStage': 'golygu-llwyfan',
  'routes.addObjective': 'ychwanegu-amcan',
  'routes.editObjective': 'golyg-amcan',
  'routes.cloneVersion': 'fersiwn-clôn',
  'routes.courses': 'cyrsiau',
  'routes.createCourse': 'creu-cwrs',
  'routes.editCourse': 'golygu-cwrs',
  'routes.learners': 'dysgwyr',
  'routes.createLearner': 'creu-dysgwr',
  'routes.step1': 'cam-1',
  'routes.step2': 'cam-2',
  'routes.step3': 'cam-3',
  'routes.step4': 'cam-4',
  'routes.success': 'llwyddiant',
  'routes.editLearner': 'golygu-dysgwr',
  'routes.instructors': 'hyfforddwyr',
  'routes.createInstructor': 'creu-hyfforddwr',
  'routes.editInstructor': 'golygu-hyfforddwr',
  'routes.providers': 'darparwyr',
  'routes.createProvider': 'creu-darparwr',
  'routes.editProvider': 'golygu-darparwr',
  'routes.editCentres': 'canolfannau-golygu',
  'routes.centres': 'canolfannau',
  'routes.createCentre': 'creu-canolfan',
  'routes.viewCentre': 'canol',
  'routes.editCentre': 'golygu',
  'routes.assignInstructorToCentre': 'aseinio-hyfforddwr',
  'routes.addFrameworkProvider': 'ychwanegu-darparwr',
  'routes.createFacility': 'creu-cyfleuster',
  'routes.editFacility': 'golygu-cyfleuster',
  'routes.viewSession': 'golygfa-sesiwn',
  'routes.bookSession': 'bwcio-sesiwn',
  'routes.timetable': 'amserlen',
  'routes.resources': 'adnoddau',
  'routes.instructorViewObjectives': 'amcanion',
  'routes.viewLearnerObjective': 'defnyddwyr',
  'routes.learnerProgress': 'cynnydd',
  'routes.profile': 'proffil',
  'routes.editProfile': 'golygu-proffil',
  'routes.versions': 'fersiynau',
  'routes.payment-success': 'taliad-llwyddiant',
  'routes.groups': 'grwpiau',
  'routes.createGroup': 'creu-grŵp',
  'routes.viewGroup': 'grwp',
  'routes.editGroup': 'golygu',
  'routes.createGroupAdmin': 'creu-grŵp-gweinyddol',
  'routes.editGroupAdmin': 'golygu-group-admin',
  'routes.addGroupToCourseSession': 'ychwanegu-grŵp',
  'routes.addGroupToCourse': 'ychwanegu-grŵp',
  'routes.addLearnerToCourse': 'ychwanegu-ddysgwr',
  'routes.addLearnerToCourseSession': 'ychwanegu-ddysgwr',
  'routes.addLearnerToGroup': 'ychwanegu-ddysgwr',
  'routes.dragonGame': 'gêm-draig',
  'routes.systemDiagnostics': 'system-diagnosteg',
  'routes.createCentreAdmin': 'create-admin',
  'routes.createProviderAdmin': 'create-admin',
  'routes.privacyPolicy': 'polisi-preifatrwydd',
  'routes.accessibilityStatement': 'datganiad-hygyrchedd',
  'routes.termsAndConditions': 'telerau-ac-amodau',
  'routes.cookiePolicy': 'polisi-cwcis',
  'routes.schools': 'ysgolion',
  'routes.createSchool': 'creu-ysgol',
  'routes.editSchool': 'golygu-ysgol',
  'routes.createSchoolAdmin': 'creu-admin-ysgol',
  'routes.addCentreToSchool': 'add-centre',
  'routes.changePassword': 'change-password',
  'routes.reports': 'adroddiadau',
  'routes.users': 'users',
  'routes.editAdminCentres': 'edit-admin-centres',
  'routes.editInstructorCentres': 'edit-instructor-centres',
  'routes.editLearnerCentres': 'edit-learner-centres',
  'routes.offlineSession': 'offline-session',
  'routes.userImpersonation': 'user-impersonation'
};
export default cy;

import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQueries, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';

import { getUser as getInstructor } from '../../services/api/user.service';
import { UserType } from '../../const/user-type';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import { GeneralError } from '../common';
import { assignNewInstructor } from '../../services/api/course.service';
import { components } from '../../types/openapi/CourseService';
import { components as userComponents } from '../../types/openapi/UserService';
import Loading from '../common/Loading';
import InstructorDetailsTable from '../instructors-admin/InstructorDetailsTable';
import instructorKeys from '../../query-keys/instructor-key-factory';
import useCourse from '../../hooks/course/useCourse';
import useUsersForProviderCentre from '../../hooks/useUsersForProviderCentre';
import usersListsKeys from '../../query-keys/users-lists-key-factory';

function InstructorOnCourse() {
  const { courseId } = useParams();
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const intl = useIntl();
  const queryClient = useQueryClient();
  const userProviderId = profile?.providerId;
  const [showInstructorSelect, setShowInstructorSelect] =
    useState<boolean>(false);
  const [selectedInstructorId, setSelectedInstructorId] = useState<any>();
  const [instructorId, setInstructorId] = useState<number | null>();
  const [centreId, setCentreId] = useState<number | null>();

  const { courseQuery } = useCourse({
    courseId: Number(courseId),
    displayLocale
  });

  const { usersForProviderCentreQuery } = useUsersForProviderCentre({
    providerId: userProviderId,
    centreId,
    userTypeId: UserType.INSTRUCTOR
  });

  const [instructor] = useQueries({
    queries: [
      {
        queryKey: instructorKeys.instructor(instructorId, displayLocale),
        queryFn: () => getInstructor(instructorId),
        refetchOnMount: true,
        enabled: !!instructorId
      }
    ]
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { mutate: assignNewInstructorForCourse, isLoading } = useMutation(
    (updatedCourseInstructor: components['schemas']['CourseInstructorDto']) =>
      assignNewInstructor(updatedCourseInstructor),
    {
      onSuccess: (newCourse: components['schemas']['CourseInstructorDto']) => {
        setInstructorId(newCourse.instructorId);

        toast.success(
          intl.formatMessage({
            id: 'course.assign_instructor.success',
            defaultMessage:
              'The instructor was successfully changed on the course'
          }),
          { delay: 200 }
        );
        queryClient.invalidateQueries({
          queryKey: usersListsKeys.list({
            providerId: userProviderId,
            centreId,
            userTypeId: UserType.INSTRUCTOR
          })
        });
        setShowInstructorSelect(false);
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onError: (error: any) => {
        toast.error(
          intl.formatMessage({
            id: 'course.assign_instructor.error',
            defaultMessage:
              'There was an error assigning a new instructor onto this course'
          }),
          { delay: 200 }
        );
      }
    }
  );
  const handleChangeInstructorOnCourse = () => {
    if (profile?.providerId && courseQuery.data?.frameworkVersionId) {
      assignNewInstructorForCourse({
        instructorId: Number(selectedInstructorId.value),
        courseId: Number(courseId)
      });
    }
  };

  useEffect(() => {
    if (courseQuery && courseQuery.data) {
      setInstructorId(courseQuery.data.instructorId);
      setCentreId(courseQuery.data.centreId);
    }
  }, [courseQuery.data]);

  return (
    <div className="card border-0 rounded-top-right-lg mb-2">
      <div className="card-header d-flex">
        <div className="flex-grow-1">
          <h2>
            <FormattedMessage
              id="instructor"
              defaultMessage="Instructor"
              description="Instructor"
            />
          </h2>
        </div>
        <div>
          {!showInstructorSelect && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => setShowInstructorSelect(true)}
            >
              <FormattedMessage
                id="instructor.course.change"
                defaultMessage="Change instructor"
                description="Change instructor on course"
              />
            </button>
          )}
        </div>
      </div>
      <div className="card-body">
        <div className="d-flex flex-column align-items-center gap-3 mb-3">
          {showInstructorSelect && (
            <>
              <Select
                data-gdpr="true"
                className="w-100"
                options={usersForProviderCentreQuery.data?.users?.map(
                  (centreInstructor: userComponents['schemas']['UserDto']) => {
                    return {
                      label: centreInstructor.username,
                      value: centreInstructor.userId
                    };
                  }
                )}
                defaultValue={{
                  label: instructor.data?.username,
                  value: instructor.data?.userId
                }}
                placeholder="Search instructors"
                onChange={(instructorOption) =>
                  setSelectedInstructorId(instructorOption)
                }
              />
              <div className="btn-group">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowInstructorSelect(false)}
                >
                  <FormattedMessage
                    id="cancel"
                    defaultMessage="Cancel"
                    description="Cancel"
                  />
                </button>
                <button
                  type="button"
                  className="btn btn-primary ms-1"
                  disabled={!selectedInstructorId}
                  onClick={handleChangeInstructorOnCourse}
                >
                  <FormattedMessage
                    id="save_changes"
                    defaultMessage="Save changes"
                    description="Save changes"
                  />
                </button>
              </div>
            </>
          )}
        </div>
        {instructor.isFetching ? (
          <Loading />
        ) : instructor.error ? (
          <GeneralError />
        ) : instructor.data ? (
          !showInstructorSelect && (
            <InstructorDetailsTable user={instructor.data} />
          )
        ) : (
          <FormattedMessage
            id="instructor.error.none"
            defaultMessage="No instructor information found"
            description="No instructor information found"
          />
        )}
      </div>
    </div>
  );
}

export default InstructorOnCourse;

import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';

import { UserType } from '../../const/user-type';
import UserTypeTranslationIds from '../../modules/i18n/user-types/user-type-translation-ids';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import { components } from '../../types/openapi/UserService';
import LearnerIcon from '../../assets/images/learner-icon.png';
import AdminIcon from '../../assets/images/admin-icon.png';
import InstructorIcon from '../../assets/images/instructor-icon.png';
import SportManagerIcon from '../../assets/images/sport-manager-icon.png';
import SuperAdminIcon from '../../assets/images/super-admin-icon.png';

function RoleSelectCard({
  roleType,
  userPermissions
}: {
  roleType: number;
  userPermissions: components['schemas']['UserProvidersDto'][];
}) {
  const profileContext = useContext(ProfileContext);
  const { setNewProfile } = profileContext;
  const [selectedProfile, setSelectedProfile] =
    useState<components['schemas']['UserProvidersDto']>();

  const intl = useIntl();

  const navigate = useNavigate();

  // redirect to home after select profile
  const redirectAfterChoose = `/`;

  const imgSrc =
    roleType === UserType.ADMIN
      ? AdminIcon
      : roleType === UserType.INSTRUCTOR
      ? InstructorIcon
      : roleType === UserType.LEARNER
      ? LearnerIcon
      : roleType === UserType.SPORT_MANAGER
      ? SportManagerIcon
      : SuperAdminIcon;

  useEffect(() => {
    if (userPermissions.length === 1) {
      setSelectedProfile(userPermissions[0]);
    }
  }, [userPermissions]);

  const selectProfile = (
    value: components['schemas']['UserProvidersDto'] | undefined
  ) => {
    if (value !== undefined) {
      setNewProfile(value);
      toast.success(
        intl.formatMessage(
          {
            id: 'role.change.success',
            defaultMessage: 'Role has been set to {roleName} for {provider}'
          },
          {
            roleName: intl.formatMessage({
              id: UserTypeTranslationIds[value.userTypeId as UserType]
            }),
            provider: value.provider?.name
          }
        ),
        { delay: 200 }
      );
      navigate(redirectAfterChoose);
    }
  };
  return (
    <div className="card border-0 rounded-top-right-lg ">
      <div className="card-header border-0 text-center">
        <h2>
          {intl.formatMessage({
            id: UserTypeTranslationIds[roleType as UserType]
          })}
        </h2>
        <div className="rounded-circle role-card-icon mb-3">
          <img src={imgSrc} alt="" />
        </div>
      </div>

      <div className="card-body">
        {userPermissions.length > 1 && (
          <>
            <label htmlFor="role-select" className="visually-hidden">
              <FormattedMessage
                id="roleType.select"
                defaultMessage="Choose a {roleType} role"
                description="Choose a {roleType} role"
                values={{
                  roleType: intl.formatMessage({
                    id: UserTypeTranslationIds[roleType as UserType]
                  })
                }}
              />
            </label>
            <Select
              key={`role_select_key__${roleType}`}
              inputId="role-select"
              onChange={(event) => setSelectedProfile(event?.value)}
              options={userPermissions.map((userPermission) => {
                const { provider, centre } = userPermission;
                return {
                  label: centre ? centre.name : provider?.name,
                  value: userPermission
                };
              })}
              menuPlacement="auto"
              styles={{
                // Fixes the overlapping problem of the component
                menu: (provided) => ({ ...provided, zIndex: 9999 })
              }}
            />
          </>
        )}
      </div>
      <div className="card-footer border-0 d-flex justify-content-center">
        <button
          type="button"
          className="btn btn-chevron w-75"
          disabled={!selectedProfile}
          onClick={(event) => {
            event.preventDefault();
            selectProfile(selectedProfile);
          }}
        >
          <FormattedMessage
            id="choose"
            defaultMessage="Choose"
            description="Choose"
          />
        </button>
      </div>
    </div>
  );
}

export default RoleSelectCard;

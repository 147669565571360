export enum AppRoute {
  Home = 'routes.home',
  Admin = 'routes.admin',
  Login = 'routes.login',
  About = 'routes.about',
  Contact = 'routes.contact',
  LoginSSORedirect = 'routes.loginSSORedirect',
  RoleChange = 'routes.roleChange',
  RoleSelect = 'routes.role.select',
  Profile = 'routes.profile',
  EditProfile = 'routes.editProfile',
  Unauthorised = 'routes.unauthorised',
  ForgottenUsername = 'routes.login.forgotUsername',
  ForgottenPassword = 'routes.login.forgotPassword',
  RequestForgottenPassword = 'routes.login.request.forgotPassword',
  Frameworks = 'routes.frameworks',
  Framework = 'routes.framework',
  CreateFramework = 'routes.createFramework',
  ManageFrameworkTranslation = 'routes.manageFrameworkTranslation',
  ManageTranslation = 'routes.manageTranslation',
  FrameworkVersion = 'routes.version',
  EditFrameworkVersion = 'routes.editVersion',
  Stages = 'routes.stages',
  AddStage = 'routes.addStage',
  EditStage = 'routes.editStage',
  AddObjective = 'routes.addObjective',
  EditObjective = 'routes.editObjective',
  CloneFrameworkVersion = 'routes.cloneVersion',
  Courses = 'routes.courses',
  CreateCourse = 'routes.createCourse',
  EditCourse = 'routes.editCourse',
  Learners = 'routes.learners',
  CreateLearner = 'routes.createLearner',
  Step1 = 'routes.step1',
  Step2 = 'routes.step2',
  Step3 = 'routes.step3',
  Step4 = 'routes.step4',
  Success = 'routes.success',
  EditLearner = 'routes.editLearner',
  Instructors = 'routes.instructors',
  CreateInstructor = 'routes.createInstructor',
  EditInstructor = 'routes.editInstructor',
  Providers = 'routes.providers',
  CreateProvider = 'routes.createProvider',
  EditProvider = 'routes.editProvider',
  EditCentres = 'routes.editCentres',
  Centres = 'routes.centres',
  CreateCentre = 'routes.createCentre',
  ViewCentre = 'routes.viewCentre',
  EditCentre = 'routes.editCentre',
  AssignInstructorToCentre = 'routes.assignInstructorToCentre',
  AddFrameworkProvider = 'routes.addFrameworkProvider',
  CreateFacility = 'routes.createFacility',
  EditFacility = 'routes.editFacility',
  ViewSession = 'routes.viewSession',
  BookSession = 'routes.bookSession',
  Timetable = 'routes.timetable',
  Resources = 'routes.resources',
  InstructorViewObjectives = 'routes.instructorViewObjectives',
  ViewLearnerObjective = 'routes.viewLearnerObjective',
  LearnerProgress = 'routes.learnerProgress',
  Versions = 'routes.versions',
  Payment = 'routes.payment-success',
  Groups = 'routes.groups',
  CreateGroup = 'routes.createGroup',
  ViewGroup = 'routes.viewGroup',
  EditGroup = 'routes.editGroup',
  CreateGroupAdmin = 'routes.createGroupAdmin',
  EditGroupAdmin = 'routes.editGroupAdmin',
  AddGroupToCourseSession = 'routes.addGroupToCourseSession',
  AddGroupToCourse = 'routes.addGroupToCourse',
  AddLearnerToCourse = 'routes.addLearnerToCourse',
  AddLearnerToCourseSession = 'routes.addLearnerToCourseSession',
  AddLearnerToGroup = 'routes.addLearnerToGroup',
  DragonGame = 'routes.dragonGame',
  SystemDiagnostics = 'routes.systemDiagnostics',
  CreateCentreAdmin = 'routes.createCentreAdmin',
  CreateProviderAdmin = 'routes.createProviderAdmin',
  PrivacyPolicy = 'routes.privacyPolicy',
  AccessibilityStatement = 'routes.accessibilityStatement',
  TermsAndConditions = 'routes.termsAndConditions',
  CookiePolicy = 'routes.cookiePolicy',
  Schools = 'routes.schools',
  CreateSchool = 'routes.createSchool',
  EditSchool = 'routes.editSchool',
  CreateSchoolAdmin = 'routes.createSchoolAdmin',
  AddCentreToSchool = 'routes.addCentreToSchool',
  ChangePassword = 'routes.changePassword',
  Reports = 'routes.reports',
  Users = 'routes.users',
  EditAdminCentres = 'routes.editAdminCentres',
  EditInstructorCentres = 'routes.editInstructorCentres',
  EditLearnerCentres = 'routes.editLearnerCentres',
  OfflineSession = 'routes.offlineSession',
  UserImpersonation = 'routes.userImpersonation'
}

export const AppRouteTitles = new Map([
  [AppRoute.Home, 'home.title'],
  [AppRoute.Admin, 'admin.title'],
  [AppRoute.Login, 'login.title']
]);

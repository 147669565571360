import React, { useContext, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { ProfileContext } from '../../../modules/profile/ProfileProvider';
import { CurrentAndNewPasswords } from '../../../types/CurrentAndNewPasswords.type';
import hasNoSpaces from '../../../utils/auth/password-validation/hasNoSpaces';
import isEqual from '../../../utils/auth/password-validation/isEqual';
import passesCharacterValidationRules from '../../../utils/auth/password-validation/passesCharacterValidationRules';
import Asterisk from '../../Asterisk';
import InlineFormError from '../../ui/InlineFormError';
import PasswordRequirements from '../password-reset/PasswordRequirements';
import PasswordErrorSummary from '../PasswordErrorSummary';

function ChangePasswordForm({
  onSubmit,
  submitButtonMessage,
  formDisabled
}: {
  onSubmit: (value: CurrentAndNewPasswords) => void;
  submitButtonMessage: any;
  formDisabled: boolean;
}) {
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  const errorSummary = useRef<HTMLDivElement>(null);

  const {
    register,
    handleSubmit,
    getValues,
    trigger,
    formState: { errors, isDirty, isValid }
  } = useForm({
    // validate on submit - button must be enabled for this to work
    mode: 'onSubmit',
    // after submit, revalidate form when user completes a field
    reValidateMode: 'onBlur',
    // Set to false so that on an unsuccessful submit the error summary can be focused instead
    shouldFocusError: false,
    defaultValues: {
      password: '',
      passwordConfirm: '',
      username: profile?.user?.username || '',
      currentPassword: ''
    }
  });

  const submitErrorHandler = () => {
    if (errorSummary.current) {
      errorSummary.current.removeAttribute('hidden');
      errorSummary.current.focus();
    }
  };

  return (
    <div className="d-flex flex-column w-100">
      {!isValid && (
        <PasswordErrorSummary
          errors={errors}
          errorSummary={errorSummary}
          summaryHeader={
            <FormattedMessage
              id="form.password.updated.error.summary.header"
              defaultMessage="There were errors with the updated password you submitted."
              description="There were errors with the updated password you submitted."
            />
          }
        />
      )}

      <form
        onSubmit={handleSubmit(onSubmit, submitErrorHandler)}
        className="d-flex flex-column"
      >
        <div>
          <input
            id="UserName"
            type="hidden"
            value={profile?.user?.username || undefined}
            className="form-control"
            aria-hidden="true"
            {...register('username', { required: true })}
          />
        </div>
        <div className="mb-3">
          {' '}
          <label htmlFor="currentPassword" className="form-label w-100">
            <Asterisk />
            <FormattedMessage
              id="form.label.current-password"
              defaultMessage="Current Password"
            />
            <input
              id="currentPassword"
              type="password"
              className="form-control"
              autoComplete="off"
              aria-required="true"
              aria-invalid={errors.currentPassword ? 'true' : 'false'}
              {...register('currentPassword', { required: true })}
            />
            {errors.currentPassword?.type === 'required' && (
              <InlineFormError
                message={
                  <FormattedMessage
                    id="form.password.required"
                    defaultMessage="Password is required"
                    description="Password is required"
                  />
                }
              />
            )}
          </label>
        </div>

        <div>
          <label htmlFor="password" className="form-label w-100">
            <Asterisk />
            <FormattedMessage
              id="form.label.password.new"
              defaultMessage="New password"
            />
            <input
              id="password"
              type="password"
              className={`form-control ${errors.password && 'is-invalid'}`}
              aria-required="true"
              aria-describedby="password_error password-requirements"
              aria-invalid={errors.password ? 'true' : 'false'}
              autoComplete="off"
              {...register('password', {
                required: true,
                minLength: 8,
                validate: {
                  hasNoSpaces: (v) => hasNoSpaces(v),
                  passesCharacterValidationRules: (v) =>
                    passesCharacterValidationRules(v)
                }
              })}
            />
            {errors.password?.type === 'required' && (
              <InlineFormError
                message={
                  <FormattedMessage
                    id="form.password.required"
                    defaultMessage="Password is required"
                    description="Password is required"
                  />
                }
              />
            )}
            {errors.password && errors.password?.type !== 'required' && (
              <InlineFormError
                message={
                  <FormattedMessage
                    id="form.password.invalid"
                    defaultMessage="The password you entered is not valid"
                    description="The password you entered is not valid"
                  />
                }
              />
            )}
          </label>
          <PasswordRequirements />
        </div>

        <div>
          <label htmlFor="password-confirm" className="form-label w-100">
            <Asterisk />
            <FormattedMessage
              id="form.label.password.confirm"
              defaultMessage="Confirm Password"
            />
            <input
              id="password-confirm"
              type="password"
              className={`form-control ${
                errors.passwordConfirm && 'is-invalid'
              }`}
              aria-invalid={errors.passwordConfirm ? 'true' : 'false'}
              aria-describedby="password_confirm_error"
              autoComplete="off"
              {...register('passwordConfirm', {
                required: true,
                onChange: () => {
                  trigger('passwordConfirm');
                },
                validate: {
                  isEqual: (v) => isEqual(getValues('password'), v)
                }
              })}
            />
            {errors.password?.type === 'required' && (
              <InlineFormError
                message={
                  <FormattedMessage
                    id="form.password.confirm.required"
                    defaultMessage="Confirm password is required"
                    description="Confirm password is required"
                  />
                }
              />
            )}
            {errors.passwordConfirm?.type === 'isEqual' && (
              <InlineFormError
                message={
                  <FormattedMessage
                    id="form.password.isNotEqual"
                    defaultMessage="Passwords must match"
                    description="Passwords must match"
                  />
                }
              />
            )}
          </label>
        </div>
        <button
          className="btn btn-primary align-self-center mt-3"
          type="submit"
          disabled={formDisabled && !isDirty}
        >
          {submitButtonMessage}
        </button>
      </form>
    </div>
  );
}
export default ChangePasswordForm;

/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/dot-notation */
import axios from 'axios';
import pascalcaseKeys from 'pascalcase-keys';
import { CourseUserStars } from '../../types/CourseUserStars.type';
import { ObjectiveUserStars } from '../../types/ObjectiveUserStars.type';

import { components } from '../../types/openapi/CourseService';
import { bearerToken } from '../bearer-token';
import camelCaseResponse from '../camel-case-interceptor';
import expiredTokenInterceptor from '../refresh-access-token.interceptor';

const BASE_URL = `${process.env.REACT_APP_COURSE_API_URL}/api`;

export const coursesApi = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

coursesApi.interceptors.request.use(
  (config) => {
    const token: string = bearerToken();
    if (token && config && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);
coursesApi.interceptors.response.use(
  (response) => camelCaseResponse(response),
  (error) => expiredTokenInterceptor(coursesApi, error)
);

export const createCourse = async (
  course: components['schemas']['CourseDto'],
  lang?: string
) => {
  const params = {
    lang
  };
  const body = pascalcaseKeys({ course }, { deep: true });
  const response = await coursesApi.post<components['schemas']['CourseDto']>(
    'courses',
    body,
    { params }
  );

  return response.data;
};

export const getCoursesFrameworksForProvider = async (
  providerId: number,
  lang?: string
) => {
  if (!providerId) {
    throw Error('no id provided');
  }

  const params = {
    lang
  };

  const response = await coursesApi.get<
    components['schemas']['FrameworkFilterDto'][]
  >(`courses/providers/${providerId}/frameworks`, {
    params
  });

  return response.data;
};

export const getActiveCoursesCount = async (
  providerId: number | null | undefined,
  lang?: string
) => {
  const params = {
    lang
  };

  const response = await coursesApi.get<number>(
    `courses/providers/${providerId}/count/active`,
    {
      params
    }
  );
  return response.data;
};
// only used for super admin - all other users to use user specific paged APIs
export const getCourses = async (
  pageIndex?: number | null | undefined,
  pageSize?: number | null | undefined,
  sortField?: string | null,
  sortDirection?: string | null,
  filtering?: any,
  lang?: string
) => {
  const params = {
    pageIndex,
    pageSize,
    sortField,
    sortDirection,
    ...filtering,
    lang
  };

  const response = await coursesApi.get<
    components['schemas']['PagedCourseDto']
  >('courses', { params });
  return response.data;
};

export const getCoursesForProvider = async (
  providerId: number | null | undefined,
  pageIndex?: number | null | undefined,
  pageSize?: number | null | undefined,
  sortField?: string | null,
  sortDirection?: string | null,
  filtering?: any,
  lang?: string
) => {
  if (!providerId) {
    throw Error('no id provided');
  }

  const params = {
    pageIndex,
    pageSize,
    sortField,
    sortDirection,
    ...filtering,
    lang
  };

  const response = await coursesApi.get<
    components['schemas']['PagedCourseDto']
  >(`courses/providers/${providerId}`, {
    params
  });

  return response.data;
};

export const getCoursesForProviderCentre = async (
  providerId: number | null | undefined,
  centreId: number | null | undefined,
  pageIndex?: number | null | undefined,
  pageSize?: number | null | undefined,
  sortField?: string | null,
  sortDirection?: string | null,
  filtering?: any,
  lang?: string
) => {
  if (!providerId || !centreId) {
    throw Error('no id provided');
  }

  const params = {
    pageIndex,
    pageSize,
    sortField,
    sortDirection,
    ...filtering,
    lang
  };

  const response = await coursesApi.get<
    components['schemas']['PagedCourseDto']
  >(`courses/providers/${providerId}/centres/${centreId}`, { params });
  return response.data;
};

export const createCourseSession = async (
  courseId: number,
  CourseSession: any
) => {
  const body = {
    CourseSession
  };
  const response = await coursesApi.post<components['schemas']['CourseDto']>(
    `courses/${courseId}/sessions`,
    JSON.stringify(body)
  );

  return response.data;
};

export const getCourse = async (courseId: number, lang?: string) => {
  if (!courseId) {
    throw Error('no id provided');
  }
  const params = {
    lang
  };

  const response = await coursesApi.get<components['schemas']['CourseDto']>(
    `courses/${courseId}`,
    { params }
  );
  return response.data;
};

export const getCoursesSessionsForCourse = async (
  courseId: number,
  lang?: string
) => {
  const params = {
    lang
  };

  const response = await coursesApi.get<
    components['schemas']['CourseSessionDto'][]
  >(`courses/${courseId}/sessions`, { params });
  return response.data;
};

export const getCourseSessionBookingsForUser = async (
  courseId: number | null | undefined,
  userId: number | null | undefined,
  lang?: string
) => {
  if (!courseId || !userId) {
    throw Error('no id provided');
  }
  const params = {
    lang
  };

  const response = await coursesApi.get<
    components['schemas']['UserCourseSessionBookingDto'][]
  >(`courses/${courseId}/users/${userId}/sessions`, { params });
  return response.data;
};

export const getFrameworkCourseSessionBookingsForUser = async (
  frameworkVersionId: number | null | undefined,
  userId: number | null | undefined,
  lang?: string
) => {
  if (!frameworkVersionId || !userId) {
    throw Error('no id provided');
  }
  const params = {
    lang
  };

  const response = await coursesApi.get<
    components['schemas']['UserCourseSessionBookingDto'][]
  >(`courses/frameworkversion/${frameworkVersionId}/users/${userId}/sessions`, {
    params
  });
  return response.data;
};

export const getCourseSessionBookingForUser = async (
  courseId: number | null | undefined,
  courseSessionId: number | null | undefined,
  userId: number | null | undefined,
  lang?: string
) => {
  if (!courseId || !userId || !courseSessionId) {
    throw Error('no id provided');
  }
  const params = {
    lang
  };

  const response = await coursesApi.get<
    components['schemas']['UserCourseSessionBookingDto']
  >(`courses/${courseId}/users/${userId}/sessions/${courseSessionId}`, {
    params
  });
  return response.data;
};

export const getCourseSession = async (
  courseId: number,
  sessionId: number,
  lang?: string
) => {
  const params = {
    lang
  };

  const response = await coursesApi.get<
    components['schemas']['CourseSessionDto']
  >(`courses/${courseId}/sessions/${sessionId}`, { params });
  return response.data;
};

export const createStripeUrlForPayment = async (paymentDetails: {
  courseSessionId: number;
  courseId: number;
  userId: number;
  providerId: number;
  char22Descriptor: string;
  frontEndHost: string;
  langCode: string | undefined;
}) => {
  if (!paymentDetails.langCode) {
    throw Error('no lang code');
  }
  const myObj = {
    paymentDetails
  };
  const body = pascalcaseKeys(myObj, { deep: true });
  const response = await coursesApi.post<string>(
    `courses/createStripeUrlForPayment`,
    JSON.stringify(body)
  );

  return response.data;
};

export const bookUserOnToCourse = async (courseId: number, userId: number) => {
  const body = {
    User: { UserId: userId }
  };
  const response = await coursesApi.post<
    components['schemas']['UserCourseSessionBookingDto']
  >(`courses/${courseId}/booking`, JSON.stringify(body));

  return response.data;
};

export const getMembersForCourse = async (courseId: number, lang?: string) => {
  const params = {
    lang
  };

  const response = await coursesApi
    .get<components['schemas']['UserDto'][]>(`courses/${courseId}/users`, {
      params
    })
    .catch((error) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error(error.response.data);
        console.error(error.response.status);
        console.error(error.response.headers);
        // users not found
        // if (error.response.status === 404) {

        //   return
        // }
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser
        console.error(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error', error.message);
      }
      console.error(error.config);
      return error;
    });

  if (response?.response?.status === 404) {
    return [];
  }

  return response.data;
};

export const getMembersForCourseSession = async (
  courseId: number,
  sessionId: number,
  lang?: string
) => {
  const params = {
    lang
  };

  const response = await coursesApi.get<components['schemas']['UserDto'][]>(
    `courses/${courseId}/sessions/${sessionId}/users`,
    {
      params
    }
  );
  return response.data;
};

export const bookUserOnToCourseSession = async (
  courseId: number,
  sessionId: number,
  userId: number
) => {
  const body = {
    UserCourseSessionBookings: [
      { courseSessionId: sessionId, courseId, userId }
    ]
  };
  const response = await coursesApi.post<
    components['schemas']['UserCourseSessionBookingDto']
  >(`courses/${courseId}/users/${userId}/sessions`, JSON.stringify(body));

  return response.data;
};

export const deleteCourseSessionBookingForUser = async (
  courseId: number,
  userId: number,
  sessionId: number
) => {
  const response = await coursesApi.delete<
    components['schemas']['UserCourseSessionBookingDto']
  >(`courses/${courseId}/users/${userId}/sessions/${sessionId}`);

  return response.data;
};

export const assignNewInstructor = async (
  updatedCourseInstructor: components['schemas']['CourseInstructorDto']
) => {
  const body = pascalcaseKeys(updatedCourseInstructor, { deep: true });
  const response = await coursesApi.put<
    components['schemas']['CourseInstructorDto']
  >(
    `courses/${updatedCourseInstructor.courseId}/instructor`,
    JSON.stringify(body)
  );
  return response.data;
};

export const getCoursesForProviderInstructor = async (
  providerId: number | null | undefined,
  instructorId: number | null | undefined,
  pageIndex?: number | null | undefined,
  pageSize?: number | null | undefined,
  sortField?: string | null,
  sortDirection?: string | null,
  filtering?: any,
  lang?: string
) => {
  if (!providerId || !instructorId) {
    throw Error('no id provided');
  }

  const params = {
    pageIndex,
    pageSize,
    sortField,
    sortDirection,
    ...filtering,
    lang
  };

  const response = await coursesApi.get<
    components['schemas']['PagedCourseDto']
  >(`courses/providers/${providerId}/instructors/${instructorId}`, { params });
  return response.data;
};

export const getCourseSessionsForInstructorForDate = async (
  instructorId: number | null | undefined,
  startDate?: string,
  endDate?: string,
  lang?: string
) => {
  if (!instructorId) {
    throw Error('no id provided');
  }
  const params = {
    lang,
    startDate,
    endDate
  };

  const response = await coursesApi.get<
    components['schemas']['CourseSessionDto'][]
  >(`courses/instructors/${instructorId}/courseSessions`, { params });
  return response.data;
};

export const getCourseSessionsForLearnerForDateRange = async (
  userId: number | null | undefined,
  startDate?: string,
  endDate?: string,
  lang?: string
) => {
  if (!userId) {
    throw Error('no id provided');
  }
  const params = {
    lang,
    startDate,
    endDate
  };

  const response = await coursesApi.get<
    components['schemas']['CourseSessionDto'][]
  >(`courses/learners/${userId}/courseSessions`, { params });
  return response.data;
};

export const getCourseSessionsForLearnerAvailableToAttendForDateRange = async (
  userId: number | null | undefined,
  startDate?: string,
  endDate?: string,
  lang?: string
) => {
  if (!userId) {
    throw Error('no id provided');
  }
  const params = {
    lang,
    startDate,
    endDate
  };

  const response = await coursesApi.get<
    components['schemas']['CourseSessionDto'][]
  >(`courses/users/${userId}/getavailable`, { params });
  return response.data;
};

export const getCourseSessionsFullForDateRange = async (
  userId: number,
  providerId: number,
  startDate?: string,
  endDate?: string,
  lang?: string
) => {
  if (!userId) {
    throw Error();
  }
  const params = {
    lang,
    startDate,
    endDate
  };

  const response = await coursesApi.get<
    components['schemas']['CourseSessionDto'][]
  >(`courses/provider/${providerId}/fullybooked/usernoton/${userId}`, {
    params
  });
  return response.data;
};

export const updateCourseSession = async (
  courseId: number,
  sessionId: number,
  userId: number,
  attended: boolean
) => {
  if (!courseId || !sessionId || !userId) {
    throw Error();
  }
  const attendance = {
    userCourseSessionBooking: { attended }
  };
  const body = pascalcaseKeys(attendance, { deep: true });
  const response = await coursesApi.put(
    `courses/${courseId}/users/${userId}/sessions/${sessionId}`,
    JSON.stringify(body)
  );
  return response.data;
};

export const getUserCourseSessionBookingObjectives = async (
  courseId: number | null | undefined,
  userId: number | null | undefined,
  sessionId: number | null | undefined,
  lang?: string
) => {
  if (!courseId || !userId || !sessionId) {
    throw Error('no id provided');
  }
  const params = {
    lang
  };

  const response = await coursesApi.get<
    components['schemas']['UserCourseSessionObjectiveDto'][]
  >(`courses/${courseId}/users/${userId}/sessions/${sessionId}/objectives`, {
    params
  });
  return response.data;
};
/**
 * @deprecated this is not used anywhere
 */
export const getObjectiveStarsByFrameworkVersion = async (
  frameworkVersionId: number | null | undefined,
  stageId: number | null | undefined,
  userId: number | null | undefined,
  lang?: string
) => {
  if (!frameworkVersionId || !stageId || !userId) {
    throw Error('no id provided');
  }
  const params = {
    lang
  };

  const response = await coursesApi.get<
    components['schemas']['ObjectiveDto'][]
  >(
    `courses/frameworkversion/${frameworkVersionId}/stage/${stageId}/user/${userId}`,
    { params }
  );
  return response.data;
};

export const getObjectivesByFrameworkVersion = async (
  frameworkVersionId: number | null | undefined,
  stageId: number | null | undefined,
  userId: number | null | undefined,
  lang?: string
) => {
  if (!frameworkVersionId || !stageId || !userId) {
    throw Error('no id provided');
  }
  const params = {
    lang
  };

  const response = await coursesApi.get<
    components['schemas']['ObjectiveDto'][]
  >(
    `courses/user/${userId}/frameworkversion/${frameworkVersionId}/stage/${stageId}`,
    { params }
  );
  return response.data;
};

/**
 * @deprecated this has been replaced
 */
export const getStarsForObjective = async (
  courseId: number | null | undefined,
  objectiveId: number | null | undefined,
  userId: number | null | undefined,
  lang?: string
) => {
  if (!courseId || !objectiveId || !userId) {
    throw Error('no id provided');
  }
  const params = {
    lang
  };

  const response = await coursesApi.get<ObjectiveUserStars>(
    `courses/${courseId}/objectives/${objectiveId}/users/${userId}`,
    {
      params
    }
  );
  return response.data;
};

/**
 * @deprecated this has been replaced by getStarsForFramework
 */
export const getStarsForCourse = async (
  courseId: number | null | undefined,
  userId: number | null | undefined,
  lang?: string
) => {
  if (!courseId || !userId) {
    throw Error('no id provided');
  }
  const params = {
    lang
  };

  const response = await coursesApi.get<CourseUserStars[]>(
    `courses/${courseId}/users/${userId}/getStars`,
    {
      params
    }
  );
  return response.data;
};

// get all the stars the user has achieved on a framework, regardless on when or which course/session they were awarded
export const getStarsForFramework = async (
  frameworkVersionId: number | null | undefined,
  userId: number | null | undefined,
  lang?: string
) => {
  if (!frameworkVersionId || !userId) {
    throw Error('no id provided');
  }
  const params = {
    lang
  };

  const response = await coursesApi.get<CourseUserStars[]>(
    `courses/frameworkversion/${frameworkVersionId}/users/${userId}/getStars`,
    {
      params
    }
  );
  return response.data;
};

// get stars for a single user for a specific session
/**
 * @deprecated we are not using this any longer - replaced by getStarsForFramework and/or getStarsForUsersCourseSession
 */
export const getStarsForCourseSession = async (
  courseId: number | null | undefined,
  sessionId: number | null | undefined,
  userId: number | null | undefined,
  lang?: string
) => {
  if (!courseId || !userId || !sessionId) {
    throw Error('no id provided');
  }
  const params = {
    lang
  };

  const response = await coursesApi.get<CourseUserStars[]>(
    `courses/${courseId}/session/${sessionId}/users/${userId}/getstars`,
    {
      params
    }
  );
  return response.data;
};

// get stars for a list of users for a specific session
export const getStarsForUsersCourseSession = async (
  courseId: number | null | undefined,
  sessionId: number | null | undefined,
  userIds: number[]
) => {
  if (!courseId || !userIds.length || !sessionId) {
    throw Error('no id provided');
  }

  const userIdsObj = { Users: userIds };

  const body = pascalcaseKeys(userIdsObj, { deep: true });

  const response = await coursesApi.post<CourseUserStars[]>(
    `courses/${courseId}/session/${sessionId}/users/getstars`,
    JSON.stringify(body)
  );
  return response.data;
};

export const getStageForCourseForLearner = async (
  courseId: number | null | undefined,
  userId: number | null | undefined,
  lang?: string
) => {
  if (!courseId || !userId) {
    throw Error('no id provided');
  }
  const params = {
    lang
  };

  const response = await coursesApi.get<components['schemas']['StageDto']>(
    `courses/${courseId}/users/${userId}`,
    { params }
  );
  return response.data;
};

export const getStageForFrameworkForLearner = async (
  frameworkVersionId: number | null | undefined,
  userId: number | null | undefined,
  lang?: string
) => {
  if (!frameworkVersionId || !userId) {
    throw Error('no id provided');
  }
  const params = {
    lang
  };

  const response = await coursesApi.get<components['schemas']['StageDto']>(
    `courses/frameworkversion/${frameworkVersionId}/users/${userId}`,
    { params }
  );
  return response.data;
};

/**
 * @deprecated The method should not be used - it has been replaced with userDataSync
 */
export const updateCourseSessionObjectiveForUser = async (
  courseId: number | null | undefined,
  userId: number | null | undefined,
  sessionId: number | null | undefined,
  objectiveId: number | null | undefined,
  numberOfStars: number | null | undefined
) => {
  if (
    !courseId ||
    !sessionId ||
    !userId ||
    !objectiveId ||
    numberOfStars === null ||
    numberOfStars === undefined
  ) {
    throw Error('no id provided');
  }
  // this comes back with an array as you can update multiple objectives at once
  const response = await coursesApi.put<
    components['schemas']['UserCourseSessionObjectiveTotalDto'][]
  >(
    `courses/${courseId}/users/${userId}/sessions/${sessionId}/objectives/${objectiveId}/stars/${numberOfStars}`
  );
  return response.data;
};

export const progressLearner = async (
  frameworkVersionId: number,
  userId: number,
  currentStageId: number
) => {
  if (!frameworkVersionId || !userId || !currentStageId) {
    throw Error();
  }
  const response = await coursesApi.put(
    `courses/frameworkversion/${frameworkVersionId}/users/${userId}/stages/${currentStageId}/progress`
  );
  return response.data;
};

export const undoProgressionOfLearner = async (
  frameworkVersionId: number,
  userId: number | undefined,
  stageId: number
) => {
  if (!frameworkVersionId || !userId || !stageId) {
    throw Error();
  }
  const response = await coursesApi.put(
    `courses/frameworkversion/${frameworkVersionId}/users/${userId}/stages/${stageId}/undo-progress`
  );
  return response.data;
};

export const getCourseStages = async (courseId: number, lang?: string) => {
  const params = {
    lang
  };

  const response = await coursesApi.get<
    components['schemas']['CourseStageDto'][]
  >(`courses/${courseId}/stages`, { params });

  return response.data;
};

export const removeUserFromCourse = async (
  courseId: number | undefined,
  userId: string | undefined
) => {
  if (!courseId || !userId) {
    throw Error('no id provided');
  }
  const body = { User: { userId } };
  const response = await coursesApi.delete(`courses/${courseId}/booking/`, {
    data: body
  });

  return response.data;
};

export const getCoursesForLearner = async (
  userId: number | null | undefined,
  pageIndex?: number | null | undefined,
  pageSize?: number | null | undefined,
  sortField?: string | null,
  sortDirection?: string | null,
  filtering?: any,
  lang?: string
) => {
  if (!userId) {
    throw Error('no id provided');
  }

  const params = {
    pageIndex,
    pageSize,
    sortField,
    sortDirection,
    ...filtering,
    lang
  };

  const response = await coursesApi.get<
    components['schemas']['PagedCourseDto']
  >(`courses/users/${userId}`, { params });
  return response.data;
};

export const getCourseFrameworksForLearner = async (
  userId: number | null | undefined,
  pageIndex?: number | null | undefined,
  pageSize?: number | null | undefined,
  sortField?: string | null,
  sortDirection?: string | null,
  filtering?: any,
  lang?: string
) => {
  if (!userId) {
    throw Error('no id provided');
  }

  const params = {
    pageIndex,
    pageSize,
    sortField,
    sortDirection,
    ...filtering,
    lang
  };

  const response = await coursesApi.get<
    components['schemas']['FrameworkVersionDto'][]
  >(`courses/users/${userId}/frameworks`, { params });
  return response.data;
};

export const createCourseSessionBookingForGroup = async (
  courseId: number,
  sessionId: number,
  groupId: number
) => {
  const body = {
    UserCourseSessionBookings: [
      { courseSessionId: sessionId, courseId, groupId }
    ]
  };
  const response = await coursesApi.post<
    components['schemas']['UserCourseSessionBookingDto']
  >(
    `courses/${courseId}/session/${sessionId}/groups/${groupId}`,
    JSON.stringify(body)
  );
  return response.data;
};
export const deleteCourse = async (courseId: number | null | undefined) => {
  if (!courseId) {
    throw Error('no id provided');
  }

  const response = await coursesApi.delete<components['schemas']['CourseDto']>(
    `courses/${courseId}`
  );

  return response.data;
};

export const updateCourse = async (
  courseId: number | null | undefined,
  course: Partial<components['schemas']['CourseDto']>,
  lang?: string
) => {
  if (!courseId) {
    throw Error('no id provided');
  }
  const params = {
    lang
  };

  const courseObj = { course };

  const body = pascalcaseKeys(courseObj, { deep: true });

  const response = await coursesApi.put<components['schemas']['CourseDto']>(
    `courses/${courseId}`,
    body,
    { params }
  );

  return response.data;
};

export const bookGroupOnToCourse = async (
  courseId: number,
  groupId: number
) => {
  const body = pascalcaseKeys(
    {
      group: { groupId }
    },
    { deep: true }
  );
  const response = await coursesApi.post<
    components['schemas']['UserCourseSessionBookingDto']
  >(`courses/${courseId}/groups/${groupId}/bookgroup`, JSON.stringify(body));
  return response.data;
};
/**
 * @deprecated The method should not be used - it has been replaced with getStarsForFramework
 */
export const getStarsForUserStage = async (
  courseId: number | null | undefined,
  userId: number | null | undefined,
  lang?: string
) => {
  if (!courseId || !userId) {
    throw Error('no id provided');
  }
  const params = {
    lang
  };

  const response = await coursesApi.get<
    components['schemas']['StarsForUserDto'][]
  >(`courses/${courseId}/users/${userId}/getstars`, { params });
  return response.data;
};

export const userDataSync = async (
  daySession: components['schemas']['DaySessionSync']
) => {
  if (!daySession) {
    throw Error();
  }

  // const body = pascalcaseKeys(daySession, { deep: true });
  const response = await coursesApi.post(
    `courses/userdatasync`,
    JSON.stringify(daySession)
  );
  return response.data;
};

export const createNote = async (
  courseId: number,
  sessionId: number,
  userId: number,
  noteText: string | null | undefined
) => {
  const body = pascalcaseKeys(
    {
      note: { noteText }
    },
    { deep: true }
  );
  const response = await coursesApi.post<components['schemas']['NoteDto']>(
    `courses/${courseId}/sessions/${sessionId}/users/${userId}`,
    JSON.stringify(body)
  );
  return response.data;
};

export const getNotes = async (
  courseId: number | undefined,
  sessionId: number | undefined,
  userId: number | undefined
) => {
  if (!courseId || !sessionId || !userId) {
    throw Error('no id provided');
  }
  const response = await coursesApi.get<
    components['schemas']['SessionUserNoteDto'][]
  >(`courses/${courseId}/sessions/${sessionId}/users/${userId}/notes`);
  return response.data;
};
/**
 * @deprecated Tthis is not used anywhere
 */
export const getNote = async (
  courseId: number,
  sessionId: number,
  userId: number,
  noteId: number
) => {
  if (!courseId || !sessionId || !userId || !noteId) {
    throw Error('no id provided');
  }
  const response = await coursesApi.get<
    components['schemas']['SessionUserNoteDto']
  >(
    `courses/${courseId}/sessions/${sessionId}/users/${userId}/notes/${noteId}`
  );
  return response.data;
};

export const updateNote = async (
  noteId: number | undefined,
  noteText: string | null | undefined
) => {
  if (!noteId) {
    throw Error('no id provider');
  }
  const note = {
    note: {
      noteId,
      noteText
    }
  };
  const body = pascalcaseKeys(note, { deep: true });
  const response = await coursesApi.put(
    `courses/notes/${noteId}`,
    JSON.stringify(body)
  );
  return response.data;
};

export const deleteNote = async (noteId: number | null | undefined) => {
  if (!noteId) {
    throw Error('no id provided');
  }
  const response = await coursesApi.delete<
    components['schemas']['SessionUserNoteDto']
  >(`courses/notes/${noteId}`);
  return response.data;
};

export const getCoursesForInstructor = async (
  instructorId: number | null | undefined,
  pageIndex?: number | null | undefined,
  pageSize?: number | null | undefined,
  sortField?: string | null,
  sortDirection?: string | null,
  filtering?: any,
  lang?: string
) => {
  if (!instructorId) {
    throw Error('no id provided');
  }

  const params = {
    pageIndex,
    pageSize,
    sortField,
    sortDirection,
    ...filtering,
    lang
  };

  const response = await coursesApi.get<
    components['schemas']['PagedCourseDto']
  >(`courses/instructors/${instructorId}`, { params });
  return response.data;
};

export const getCourseSessionsForCentreForDateRange = async (
  providerId: number | null | undefined,
  centreId: number | null | undefined,
  startDate?: string,
  endDate?: string,
  lang?: string
) => {
  if (!providerId || !centreId) {
    throw Error('no id provided');
  }
  const params = {
    lang,
    startDate,
    endDate
  };

  const response = await coursesApi.get<
    components['schemas']['CourseSessionDto'][]
  >(`courses/providers/${providerId}/centres/${centreId}/courseSessions`, {
    params
  });
  return response.data;
};

export const updateCourseSessionObjectiveForUserWithOnlyCourseId = async (
  courseId: number | null | undefined,
  userId: number | null | undefined,
  stageId: number | null | undefined,
  objectiveId: number | null | undefined,
  numberOfStars: number | null | undefined
) => {
  if (
    !courseId ||
    !stageId ||
    !userId ||
    !objectiveId ||
    numberOfStars === null ||
    numberOfStars === undefined
  ) {
    throw Error('no id provided');
  }
  const response = await coursesApi.put<
    components['schemas']['UserCourseSessionObjectiveTotalDto'][]
  >(
    `courses/${courseId}/users/${userId}/stage/${stageId}/objectives/${objectiveId}/stars/${numberOfStars}`
  );
  return response.data;
};

export const getAllUserCourseSessionBookingsByCourseAndStage = async (
  courseId: number | null | undefined,
  learnerId: number | null | undefined,
  instructorId: number | null | undefined
) => {
  if (!courseId || !learnerId || !instructorId) {
    throw Error('no id provided');
  }
  const params = {
    learnerId,
    instructorId
  };

  const response = await coursesApi.get<
    components['schemas']['UserCourseSessionBookingDto'][]
  >(`courses/${courseId}/session-bookings`, {
    params
  });
  return response.data;
};

export const getOfflineDataDownload = async (
  sessionId: number | null | undefined
) => {
  if (!sessionId) {
    throw Error('no id provided');
  }

  const response = await coursesApi.get<
    components['schemas']['OfflineDownloadDto']
  >(`courses/session/${sessionId}/offlinedatadownload`);
  return response.data;
};

export const getCoursesForSchool = async (
  schoolId: number | null | undefined,
  pageIndex?: number | null | undefined,
  pageSize?: number | null | undefined,
  sortField?: string | null,
  sortDirection?: string | null,
  filtering?: any,
  lang?: string
) => {
  if (!schoolId) {
    throw Error('no id provided');
  }

  const params = {
    pageIndex,
    pageSize,
    sortField,
    sortDirection,
    ...filtering,
    lang
  };

  const response = await coursesApi.get<
    components['schemas']['PagedCourseDto']
  >(`courses/schools/${schoolId}`, {
    params
  });

  return response.data;
};

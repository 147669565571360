import React, { Suspense, useContext, useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { CookieConsentProvider } from '@use-cookie-consent/react';

import PublicHeader from './landing-pages/components/PublicHeader';

import { LangContext } from '../modules/i18n/components/IntlWrapper';
import Loading from '../components/common/Loading';
import CookieBanner from '../components/cookies/CookieBanner';
import LandingFooter from '../components/layout/footer/LandingFooter';

export interface LandingLayoutProps {
  children: React.ReactNode;
}

function LandingLayout() {
  const langCtx = useContext(LangContext);
  const { displayLocale, updateLocale } = langCtx;

  const { lang } = useParams();

  useEffect(() => {
    if (!!lang && updateLocale && lang !== displayLocale) {
      updateLocale(lang);
    }
  }, [lang]);

  return (
    <CookieConsentProvider
      useCookieConsentHooksOptions={{
        consentCookieAttributes: { expires: 365 }
      }}
    >
      <div className="layout">
        <CookieBanner />
        <PublicHeader />
        <main id="maincontent" className="d-flex flex-grow-1 admin-main">
          <Suspense fallback={<Loading />}>
            <Outlet />
          </Suspense>
        </main>
        <LandingFooter />
      </div>
    </CookieConsentProvider>
  );
}

export default LandingLayout;

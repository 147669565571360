import React, { lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteObject } from 'react-router-dom';
import MainContainer from '../components/layout/MainContainer';
import CreateLearner from '../components/learners-admin/create-learner/CreateLearner';
import EditLearner from '../components/learners-admin/EditLearner';
import ViewLearner from '../components/learners-admin/view-learner/ViewLearner';
import ViewLearnerOnCourse from '../components/learners-admin/ViewLearnerOnCourse';
import { AppLanguage, AppRoute } from '../const';
import { appStrings } from '../modules/i18n';
import { ErrorPage } from '../pages';
import LearnersListByRole from '../components/learners-admin/LearnersListByRole';
import { UserType } from '../const/user-type';
import ProtectedRoute from '../modules/auth/components/ProtectedRoute';
import EditLearnerCentres from '../components/learners-admin/view-learner/EditLearnerCentres';

const ManageLearners = lazy(
  () => import('../components/learners-admin/ManageLearners')
);

const Step1 = lazy(
  () => import('../components/learners-admin/create-learner/Step1')
);

const Step2 = lazy(
  () => import('../components/learners-admin/create-learner/Step2')
);

const Step3 = lazy(
  () => import('../components/learners-admin/create-learner/Step3')
);
const Success = lazy(
  () => import('../components/learners-admin/create-learner/Success')
);

export default function learnerRoutes(lang: AppLanguage | string | undefined) {
  const langVal = lang as AppLanguage;
  const routes: RouteObject = {
    path: appStrings[langVal][AppRoute.Learners],
    element: (
      <ProtectedRoute
        allowedRoles={[
          UserType.SUPER_ADMIN,
          UserType.ADMIN,
          UserType.INSTRUCTOR
        ]}
      >
        <MainContainer />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    handle: {
      crumb: () => (
        <FormattedMessage
          id="learners"
          defaultMessage="Learners"
          description="Learners"
        />
      )
    },
    children: [
      {
        index: true,
        element: <ManageLearners />,
        errorElement: <ErrorPage />
      },
      {
        path: appStrings[langVal][AppRoute.Learners],
        element: <LearnersListByRole />,
        errorElement: <ErrorPage />
      },
      {
        path: appStrings[langVal][AppRoute.CreateLearner],
        element: (
          <ProtectedRoute allowedRoles={[UserType.SUPER_ADMIN, UserType.ADMIN]}>
            <CreateLearner />
          </ProtectedRoute>
        ),
        errorElement: <ErrorPage />,
        handle: {
          crumb: () => (
            <FormattedMessage
              id="create.learner"
              defaultMessage="Create Learner"
              description="Create Learner"
            />
          )
        },
        children: [
          {
            index: true,
            element: <Step1 />,
            errorElement: <ErrorPage />
          },
          {
            path: appStrings[langVal][AppRoute.Step2],
            element: <Step2 />,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => (
                <FormattedMessage
                  id="create.learner.step2"
                  defaultMessage="Step 2"
                  description="Step 2"
                />
              )
            }
          },
          {
            path: appStrings[langVal][AppRoute.Step3],
            element: <Step3 />,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => (
                <FormattedMessage id="step3" defaultMessage="Step 3" />
              )
            }
          },
          {
            path: appStrings[langVal][AppRoute.Success],
            element: <Success />,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => (
                <FormattedMessage
                  id="success"
                  defaultMessage="Success"
                  description="Success"
                />
              )
            }
          }
        ]
      },
      {
        path: `:learnerId`,
        errorElement: <ErrorPage />,
        handle: {
          crumb: () => (
            <FormattedMessage
              id="learner"
              defaultMessage="Learner"
              description="Learner"
            />
          )
        },
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute
                allowedRoles={[
                  UserType.SUPER_ADMIN,
                  UserType.ADMIN,
                  UserType.INSTRUCTOR
                ]}
              >
                <ViewLearner />
              </ProtectedRoute>
            ),
            errorElement: <ErrorPage />
          },
          {
            path: `${appStrings[langVal][AppRoute.EditLearner]}`,
            element: (
              <ProtectedRoute
                allowedRoles={[UserType.SUPER_ADMIN, UserType.ADMIN]}
              >
                <EditLearner />
              </ProtectedRoute>
            ),
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => (
                <FormattedMessage
                  id="learner.edit"
                  defaultMessage="Edit Learner"
                  description="Edit Learner"
                />
              )
            }
          },
          {
            path: `${appStrings[langVal][AppRoute.EditCentres]}`,
            element: <EditLearnerCentres />,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => (
                <FormattedMessage
                  id="learner.edit.centres"
                  defaultMessage="Edit Learner Centres"
                  description="Edit Learner Centres"
                />
              )
            }
          },
          {
            path: `${appStrings[langVal][AppRoute.Courses]}/:courseId`,
            element: (
              <ProtectedRoute
                allowedRoles={[
                  UserType.SUPER_ADMIN,
                  UserType.ADMIN,
                  UserType.INSTRUCTOR
                ]}
              >
                <ViewLearnerOnCourse />
              </ProtectedRoute>
            ),
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => (
                <FormattedMessage
                  id="course"
                  defaultMessage="Course"
                  description="Course"
                />
              )
            }
          }
        ]
      }
    ]
  };
  return routes;
}

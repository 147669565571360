import { useMutation } from '@tanstack/react-query';
import React, { useContext, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import ChangePasswordForm from '../components/auth/change-password/ChangePasswordForm';
import PublicPageLayout from '../components/layout/PublicPageLayout';
import useSetPassword from '../hooks/password/useSetPassword';
import { LangContext } from '../modules/i18n/components/IntlWrapper';
import { login } from '../services/api/auth.service';
import { CurrentAndNewPasswords } from '../types/CurrentAndNewPasswords.type';
import { components } from '../types/openapi/AuthService';

function ChangePassword() {
  const { setPasswordMutation } = useSetPassword();
  const intl = useIntl();
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const homeLink = `/${displayLocale.toLocaleLowerCase()}`;
  const [newLoginDetails, setNewLoginDetails] = useState<{
    Username: string;
    Password: string;
  }>();

  const successSummary = useRef<HTMLDivElement>(null);

  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState<boolean>(false);

  const { mutate: loginUser, isLoading } = useMutation(
    (currentLoginData: components['schemas']['Login']) =>
      login(currentLoginData),
    {
      onSuccess: () => {
        if (newLoginDetails) {
          const { Username: userName, Password: password } = newLoginDetails;
          if (userName && password) {
            const passwordReset = {
              userName,
              password,
              resetToken: ''
            };

            setPasswordMutation.mutate(passwordReset, {
              onSuccess: () => {
                if (successSummary.current) {
                  successSummary.current.removeAttribute('hidden');
                  successSummary.current.focus();
                }
                setIsSubmitSuccessful(true);
                setPasswordMutation.reset();
              },
              onError: () => {
                toast.error(
                  intl.formatMessage({
                    id: 'password.change.error',
                    defaultMessage: 'There was an error updating your password'
                  }),
                  { delay: 200 }
                );
              }
            });
          }
        }
      },
      onError: (error) => {
        console.error(error);
        toast.error(
          intl.formatMessage({
            id: 'password.currentPassword.error',
            defaultMessage:
              'There was an error updating your password. Please check your details carefully'
          }),
          { delay: 200 }
        );
      }
    }
  );

  const onSubmit = (passwordData: CurrentAndNewPasswords) => {
    const { username, currentPassword, password } = passwordData;
    const currentLoginData = { UserName: username, Password: currentPassword };
    const newLoginData = { Username: username, Password: password };
    setNewLoginDetails(newLoginData);

    loginUser(currentLoginData);
  };

  return (
    <PublicPageLayout
      header={
        <FormattedMessage
          id="password.change"
          defaultMessage="Change password"
        />
      }
    >
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'title.change_password',
            defaultMessage: 'Change password'
          })}
        </title>
      </Helmet>
      {!isSubmitSuccessful && (
        <ChangePasswordForm
          onSubmit={onSubmit}
          submitButtonMessage={
            <FormattedMessage
              id="changePassword.submit"
              defaultMessage="Update password"
            />
          }
          formDisabled={isLoading}
        />
      )}
      <section
        className="alert alert-success bg-white mt-3"
        tabIndex={-1}
        ref={successSummary}
        hidden
      >
        <FormattedMessage
          id="form.passwordReset.success"
          defaultMessage="Password has been reset"
        />
        <br />
        <Link to={homeLink}>
          <FormattedMessage
            id="dashboard.link"
            defaultMessage="Go to dashboard"
          />
        </Link>
      </section>
    </PublicPageLayout>
  );
}

export default ChangePassword;
